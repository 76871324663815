.fade-out {
  animation: fadeOut 0.3s;
  -webkit-animation: fadeOut 0.3s;
  -moz-animation: fadeOut 0.3s;
  -o-animation: fadeOut 0.3s;
  -ms-animation: fadeOut 0.3s;
  animation-timing-function: cubic-bezier(0.64, 0.25, 1, 0.75);
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
